import { connect } from 'react-redux';
import GetAQuoteHero from './GetAQuoteHero';
import { generateQuotes, hidePostcodeError, returnToPostcodesInput } from './GetAQuoteHeroActions';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { isMobile } from 'react-device-detect';
import { navigate } from 'gatsby';

class GetAQuoteHeroContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupPostcode: '',
      dropoffPostcode: ''
    };
  }

  componentDidMount() {
    if (!isMobile && !!this.pickupInput) {
      this.pickupInput.focus();
    }

    this.setState({
      pickupPostcode: this.props.getAQuote.pickupPostcode || '',
      dropoffPostcode: this.props.getAQuote.dropoffPostcode || ''
    })
  }

  handleShowPrices = () => {
    this.props.generateQuotes(this.state.pickupPostcode, this.state.dropoffPostcode);
    ReactGA.event({
      category: 'Browsing',
      action: 'Show prices clicked - AppHero'
    });
  };

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.getAQuote.showQuotes && !this.props.getAQuote.showQuotes) {
      ReactGA.event({
        category: 'Browsing',
        action: 'Quote shown successfully - AppHero',
        label: 'Hero'
      });
      ReactPixel.track('Lead', {
        content_name: 'Quote shown',
        content_category: 'Hero'
      });
    }
  }

  handlePickupPostcodeChange = (e) => {
    this.setState({
      pickupPostcode: e.target.value
    })
  };

  handlePickupPostcodeKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.dropoffInput.focus()
    }
  };

  handleDropoffPostcodeChange = (e) => {
    this.setState({
      dropoffPostcode: e.target.value
    })
  };

  handleDropoffPostcodeKeyPress = (e) => {
      if (e.key === 'Enter') {
        this.props.generateQuotes(this.state.pickupPostcode, this.state.dropoffPostcode)
      }
  };

  render() {
    return (
      <GetAQuoteHero
        {...this.props}
        handlePickupClick={this.handlePickupClick}
        handleOnClickRemindMeLater={this.handleOnClickRemindMeLater}
        handleOnClickWebBooking={this.handleOnClickWebBooking}
        handleOnClickDownload={this.handleOnClickDownload}
        handleDropoffClick={this.handleDropoffClick}
        handleShowPrices={this.handleShowPrices}
        handlePickupPostcodeChange={this.handlePickupPostcodeChange}
        handleDropoffPostcodeChange={this.handleDropoffPostcodeChange}
        handlePickupPostcodeKeyPress={this.handlePickupPostcodeKeyPress}
        handleDropoffPostcodeKeyPress={this.handleDropoffPostcodeKeyPress}

        pickupPostcode={this.state.pickupPostcode}
        dropoffPostcode={this.state.dropoffPostcode}

        setPickupRef={this.setPickupRef}
        setDropoffRef={this.setDropoffRef}
      />
    )
  }

  setPickupRef = (ref) => this.pickupInput = ref;
  setDropoffRef = (ref) => this.dropoffInput = ref;


  // Tracking:
  handlePickupClick = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Focused on pickup postcode input - AppHero',
      label: 'Hero'
    });
  };

  handleDropoffClick = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Focused on dropoff postcode input - AppHero',
      label: 'Hero'
    });
  };

  handleOnClickDownload = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Download button clicked - AppHero',
      label: 'Hero'
    });
    ReactPixel.track('InitiateCheckout', {
      content_name: 'Clicked Download Button',
      content_category: 'Hero'
    });
  };

  handleOnClickWebBooking = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Book online clicked - AppHero',
      label: 'Hero'
    });
    ReactPixel.track('InitiateCheckout', {
      content_name: 'Clicked Web Booking Button',
      content_category: 'Hero'
    });
    navigate('/book')
  };

  handleOnClickRemindMeLater = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Remind me later clicked - AppHero',
      label: 'Hero'
    });
    ReactPixel.track('Schedule', {
      content_name: 'Clicked Remind Me Later Button',
      content_category: 'Hero'
    });
    navigate('/remind-me-later');
  };
}

function mapStateToProps(state) {
  return {
    getAQuote: state.getAQuote
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goBack: () => dispatch(returnToPostcodesInput()),
    focusInput: () => dispatch(hidePostcodeError()),
    generateQuotes: (pickup, dropoff) => {
      dispatch(generateQuotes(pickup, dropoff))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetAQuoteHeroContainer);
