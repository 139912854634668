import React from 'react';
import FeatureList from '../containers/sections/FeatureList/FeatureList';
import AppInterface from '../containers/sections/AppInterface/AppInterface';
import PhoneBooking from '../containers/sections/PhoneBooking/PhoneBooking';
import CredibilitySection from '../containers/sections/Credibility/Credibility';
import HelpLifting from '../containers/sections/HelpLifting/HelpLifting';
import SwipeFeaturedReviews from '../containers/reviews/SwipeableReviews/SwipeableReviews';
import COLORS from '../styles/colors';
import HowItWorksContainer from '../containers/sections/HowItWorks/HowItWorksContainer';
import Layout from '../containers/gatsby/layout';
import QuoteGeneratorContainer from '../containers/heroes/GetAQuoteHero/GetAQuoteHeroContainer';

const GetAQuotePage = (props) => (
  <Layout location={props.location}>
    <QuoteGeneratorContainer/>
    <CredibilitySection/>
    <SwipeFeaturedReviews background={COLORS.white}/>
    <FeatureList/>
    <AppInterface/>
    <HelpLifting/>
    <HowItWorksContainer/>
    <PhoneBooking/>
  </Layout>
);

export default GetAQuotePage;
