import COLORS from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';
import { FONTS } from '../../../styles/fonts';

const styles = StyleSheet.create({
  root: {
    background: COLORS.kgGreen,
  },
  content: {
    margin: '0 auto',
    display: 'flex',
    maxWidth: 1200,
    height: 'auto',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    padding: '0 24px',
  },
  postcodesContainer: {
    paddingBottom: 48,
    display: 'flex',
    flexDirection: 'column'
  },
  h1: {
    color: COLORS.white,
    fontSize: 48,
    marginBottom: 16,
    lineHeight: '48px',
    fontFamily: FONTS.Headers,
    [QUERIES.desktop]: {
      fontSize: 72,
      lineHeight: '72px'
    }
  },
  atYourDoorstep: {
    color: COLORS.kgDarkGreen,
    fontSize: 22,
    marginTop: 0,
  },
  inputsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: 4,
    overflow: 'hidden'
  },
  input: {
    textTransform: 'uppercase',
    fontSize: 22,
    flex: 1,
    padding: '22px 28px',
    border: 'none',
    background: '#05c39a',
    color: COLORS.white,
    fontWeight: 700,
    ':hover': {
      background: '#05b189'
    },
    ':focus': {
      background: '#05b189'
    },
    '::-webkit-input-placeholder': {
      textTransform: 'none',
      color: COLORS.black,
      opacity: 0.35
    }
  },
  heroImgContainer: {
    flex: 1,
    paddingTop: 32,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '@media only screen and (max-width: 1200px)': {
      display: 'none'
    }
  },
  heroImg: {
    width: 600,
    height: 787
  },
  topSeparator: {
    height: 80,
    '@media only screen and (max-width: 1200px)': {
      display: 'none'
    }
  },
  error: {
    margin: 0,
    color: '#710d51',
    fontWeight: 900,
    fontSize: 18,
    padding: 8
  },
  quotesContainer: {
    background: '#05c39a',
    borderRadius: 4
  },
  quoteRow: {
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '2px solid ' + COLORS.kgGreen
  },
  vanImg: {
    height: 64,
    paddingRight: 20
  },
  quoteContent: {
    borderRadius: 4
  },
  vanName: {
    margin: 0,
    fontSize: 18,
    color: COLORS.white,
    opacity: 0.85
  },
  price: {
    margin: 0,
    fontSize: 18,
    color: COLORS.white
  },
  quoteDesc: {
    margin: 0,
    paddingTop: 4,
    fontSize: 16,
    color: COLORS.kgDarkGreen
  },
  summaryP: {
    color: COLORS.kgDarkGreen,
    fontSize: 18,
    fontWeight: 'bold',
    paddingBottom: 12
  },

  // Material UI styles:
  showPricesButton: {
    marginTop: '24px',
    backgroundColor: COLORS.brightYellow,
    color: COLORS.offBlack,
    fontWeight: '900',
    fontSize: '18px',
    padding: '24px',
    width: '100%',
    maxWidth: '400px',
    textTransform: 'none',
    // height: 80,
    // borderRadius: 80
  },
  rightIcon: {
    float: 'right'
  },
  circularProgress: {
    color: COLORS.offBlack
  },
  downloadButton: {
    backgroundColor: COLORS.justOffBlack,
    color: COLORS.white,
    fontWeight: '900',
    height: '48px',
    width: '180px',
    marginRight: '12px',
    [QUERIES.mobile]: {
      width: '100%',
      marginBottom: 16
    }
  },
  webBookingButton: {
    backgroundColor: COLORS.brightYellow,
    color: COLORS.offBlack,
    fontWeight: '900',
    height: '48px',
    width: '180px',
    marginRight: '12px',
    [QUERIES.mobile]: {
      width: '100%',
      marginBottom: 16
    }
  },
  remindMeLaterButton: {
    backgroundColor: COLORS.white,
    color: COLORS.offBlack,
    fontWeight: '900',
    height: '48px',
    width: '180px',
    marginRight: '12px',
    [QUERIES.mobile]: {
      width: '100%'
    }
  },
  mainContainer: {
    flex: 1,
    position: 'relative'
  },
  resultsContainer: {
    paddingBottom: 48,
    paddingTop: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  goBackButton: {
    marginBottom: 32,
    boxShadow: 'none',
    color: COLORS.white,
    borderColor: COLORS.white
  }
});

export default styles;
