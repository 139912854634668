import React from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './GetAQuoteHero.styles';
import { VANS } from '../../../library/vans';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Utils from '../../../library/utils';
import { KIFFGO } from '../../../library/kiffgo';
import COLORS from '../../../styles/colors';
import { css } from 'aphrodite';
import STYLES from '../../../styles/shared';

const GetAQuoteHero = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(styles.mainContainer)}>

        {!props.getAQuote.showQuotes && <div className={css(styles.postcodesContainer)}>
          <div className={css(styles.topSeparator)}/>

          <h1 className={css(styles.h1)}>Man and van made easy</h1>
          <p className={css(styles.atYourDoorstep)}>At your doorstep within 1 hour. <b>Booking is available
            24/7</b></p>
          <div className={css(styles.inputsContainer)}>
            <input onChange={props.handlePickupPostcodeChange} onFocus={props.focusInput}
                   onKeyPress={props.handlePickupPostcodeKeyPress}
                   onClick={props.handlePickupClick}
                   className={css(styles.input)}
                   placeholder={'Pickup postcode'}
                   ref={props.setPickupRef}
                   value={props.pickupPostcode}
            />
            <input onChange={props.handleDropoffPostcodeChange}
                   onFocus={props.focusInput}
                   className={css(styles.input)}
                   placeholder={'Dropoff postcode'}
                   ref={props.setDropoffRef}
                   onKeyPress={props.handleDropoffPostcodeKeyPress}
                   onClick={props.handleDropoffClick}
                   value={props.dropoffPostcode}
            />
          </div>

          <p
            className={css(styles.error, !props.getAQuote.errorMessage && STYLES.dontShow)}>* {props.getAQuote.errorMessage}</p>

          <Button className={css(styles.showPricesButton)} variant={'contained'}
                  onClick={props.handleShowPrices}>
            Show prices
            <div style={{flex: 1}}/>
            {!props.getAQuote.showButtonLoader && <Icon className={css(styles.rightIcon)}/>}
            {props.getAQuote.showButtonLoader && <CircularProgress color={'inherit'} size={20} thickness={6}/>}
          </Button>
          <div style={{flex: 1}}/>
        </div>}

        {props.getAQuote.showQuotes && <div className={css(styles.resultsContainer)}>
          <div className={css(styles.topSeparator)}/>
          <div>
            <Button variant={'outlined'} color="secondary" aria-label="Add" className={css(styles.goBackButton)}
                    onClick={props.goBack}>
              <ArrowBack/>
              Back
            </Button>
          </div>
          <div className={css(styles.quotesContainer)}>
            {Object.values(VANS).map((van, index) => {
              return (
                <div key={index} className={css(styles.quoteRow)}>
                  <img alt={van.name + ' van hire London'} className={css(styles.vanImg)} src={van.img}/>
                  <div>
                    <div className={css(STYLES.row)}>
                      <h6 className={css(styles.vanName)}>
                        {van.name} van
                      </h6>
                      <div style={{width: 12}}/>
                      <h6 className={css(styles.price)}>{props.getAQuote.estimates[van.id] &&
                      '£' + Utils.getPounds(props.getAQuote.estimates[van.id])
                      + '.' + Utils.getPennies(props.getAQuote.estimates[van.id])
                      }</h6>
                      {!props.getAQuote.estimates[van.id] &&
                      <CircularProgress color={'inherit'} size={16} thickness={6}/>}
                    </div>
                    <p className={css(styles.quoteDesc)}>{van.description}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <p className={css(styles.summaryP)}>Happy with the prices? Download the JJD app to complete your booking,
              or call us on <a
                style={{color: COLORS.kgDarkGreen, textDecoration: 'none'}}
                href={KIFFGO.phoneNumberHrefLink}>{KIFFGO.phoneNumber}</a></p>

            <div className={css(styles.fullRow)}>
              <Button
                className={css(styles.downloadButton)}
                variant={'contained'}
                href={Utils.getDownloadLink()}
                onClick={props.handleOnClickDownload}>
                Download app
              </Button>

              <Button
                className={css(styles.webBookingButton)}
                variant={'contained'}
                onClick={props.handleOnClickWebBooking}>
                Web booking
              </Button>

              <Button
                className={css(styles.remindMeLaterButton)}
                variant={'contained'}
                onClick={props.handleOnClickRemindMeLater}>
                Remind me later
              </Button>
            </div>
          </div>
        </div>}
      </div>

      <div className={css(styles.heroImgContainer, STYLES.desktopOnly)}>
        <img className={css(styles.heroImg, STYLES.noSelect)} draggable={'false'}
             src={require('../../../assets/hero/phone.png')}
             alt={'Man and van app'}
        />
      </div>
    </div>
  </div>
);

export default GetAQuoteHero;
